<template>
  <div class="buy-detail-container">
    <div class="row-left order-detail-top">
      <div :style="'width: 5rem;height: 5rem;margin: 0 0.875rem 0 0;background-image: url('+ goodInfo.storeInfo.image +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% 0%;background-size: 100% auto;background-color: transparent;border-radius: 4px;'"></div>
      <div style="width:60vw;">
        <p class="good-detail-name">{{ goodInfo.storeInfo.storeName }}</p>
        <p class="good-detail-prise"><span style="font-size: 14px;">¥</span> {{ goodInfo.storeInfo.price }}</p>
      </div>
    </div>
    <div class="order-detail-amount-container row-between">
      <p class="order-good-amount-title">数量</p>
      <p class="order-good-amount">{{ totalCount }}</p>
    </div>
    <div
      class="order-detail-prise col-center"
      :style="buyIntegral &&  couponsProductList.length ? '14rem' : buyIntegral || couponsProductList.length?'height: 11rem;' : 'height: 7.5rem;'"
    >
      <div class="order-detail-amount row-between">
        <p class="order-good-prise-title">单价</p>
        <p class="order-good-prise"><span style="font-size: 1rem;">¥</span> {{ goodInfo.storeInfo.price }}</p>
      </div>
      <div class="line"></div>
      <div
        v-if="buyIntegral"
        @click="integralUser"
        class="order-detail-amount row-between"
      >
        <p class="order-good-prise-title">积分</p>
        <p class="order-good-prise">
          <span
            v-if="userDeductIntegral"
            style="font-size: 1rem;"
          >-¥</span>
          <span v-if="userDeductIntegral">{{buyUserDeductIntegral }}</span>
          <span
            style="font-size: 12px;"
            v-if="!userDeductIntegral"
          >不使用</span>
          <img
            class="good-prise-next"
            src="../assets/icon/next.png"
          />
        </p>
      </div>
      <div
        v-if="couponsProductList.length && isCoupon == 1"
        class="line"
      ></div>
      <div
        v-if="couponsProductList.length && isCoupon == 1"
        @click="couponsUser"
        class="order-detail-amount row-between"
      >
        <p class="order-good-prise-title">优惠券</p>
        <p class="order-good-prise">
          <span
            v-if="userCouponsSelect[0].id"
            style="font-size: 1rem;"
          >-¥</span>
          <span v-if="userCouponsSelect[0].id">{{userCouponsSelect[0].couponPrice }}</span>
          <span
            style="font-size: 12px;"
            v-if="!userCouponsSelect[0].id"
          >{{couponsProductList.length}}张可用</span>
          <img
            class="good-prise-next"
            src="../assets/icon/next.png"
          />
        </p>
      </div>
      <div
        v-if="buyIntegral || couponsProductList.length"
        class="line"
      ></div>
      <div class="order-detail-amount row-between">
        <p class="order-good-prise-title">合计金额</p>
        <p class="all-order-good-prise"><span style="font-size: 1rem;">¥</span> {{ totalPrice }}</p>
      </div>
    </div>
    <div
      v-if="buyType !== '0' && !(this.isWechat() && buyType == '2')"
      :style="isWechat() || buyType == '1' || buyType == '2' ? 'height: 60px;': 'height: 120px;'"
      class="order-detail-prise-buy col-center"
    >
      <!-- 微信支付 -->
      <div
        v-if="buyType == '1' || buyType == '3'"
        @click="selectPay('wx')"
        :style="isWechat() || buyType == '1' ? 'margin-bottom: 0px;': ''"
        class="order-detail-wx row-between"
      >
        <div class="row-center">
          <img
            class="wx-icon"
            src="../assets/icon/wx_pay.png"
          />
          <p class="wx-tip">微信支付</p>
        </div>
        <img
          id="wxSelect"
          :style="wxPay ? 'display: block;': 'display: none;'"
          class="buy-wx-select"
          src="../assets/icon/donation/select.png"
        />
        <img
          id="wxCancer"
          :style="wxPay ? 'display: none;': 'display: block;'"
          class="buy-wx-cancer"
          src="../assets/icon/donation/cancer.png"
        />
      </div>

      <div
        v-if="!isWechat() && buyType == '3'"
        class="line"
      ></div>
      <!-- 支付宝支付 -->
      <div
        v-if="!isWechat() && (buyType == '2' || buyType == '3')"
        @click="selectPay('zfb')"
        :style="buyType == '2' ? 'margin-top: 0px;': ''"
        class="order-detail-zfb row-between"
      >
        <div class="row-center">
          <img
            class="zfb-icon"
            src="../assets/icon/zfb.png"
          />
          <p class="zfb-tip">支付宝支付</p>
        </div>
        <img
          id="zfbSelect"
          class="buy-zfb-select"
          :style="this.buyType == '2' ? 'display: block;': 'display: none;'"
          src="../assets/icon/donation/select.png"
        />
        <img
          id="zfbCancer"
          :style="this.buyType == '2' ? 'display: none;': 'display: block;'"
          class="buy-zfb-cancer"
          src="../assets/icon/donation/cancer.png"
        />
      </div>

    </div>

    <div class="order-pay row-between">
      <div>
        <p class="good-detail-bottom-prise"><span style="font-size: 1.1rem;">¥</span> {{ payPrice }}</p>
      </div>
      <button
        @click="getAppId()"
        class="pay-now"
        :class="buyType == '0' || (this.isWechat() && buyType == '2') ? 'no-access-pay': ''"
      >立即支付</button>
    </div>
    <!-- 积分使用 -->
    <van-popup
      v-model="isShowIntegral"
      @close="closeUserIntegral"
      closeable
      position="bottom"
      style="height: 280px;background: #262626;position: fixed;bottom: 0px !important;left: 0;"
    >
      <p class="integral-rule-title">积分（{{allIntegral}}可用）</p>
      <div class="popup-line"></div>
      <div
        style="margin-top:20px;"
        class="row-between"
        @click="checkSelect(1)"
      >
        <p class="popup-title">暂不使用</p>
        <img
          class="popup-radio"
          v-if="radioSelect != 1"
          src="../assets/icon/integral/radio.png"
        />
        <img
          class="popup-radio"
          v-else
          src="../assets/icon/integral/radio-select.png"
        />
      </div>
      <div
        style="margin-top:20px;"
        class="row-between"
        @click="checkSelect(2)"
      >
        <p class="popup-title">最高抵扣<span>¥{{ buyDeductIntegral}}</span>使用{{deductIntegral}}积分</p>
        <img
          class="popup-radio"
          v-if="radioSelect != 2"
          src="../assets/icon/integral/radio.png"
        />
        <img
          class="popup-radio"
          v-else
          src="../assets/icon/integral/radio-select.png"
        />
      </div>
      <div
        style="margin-top:20px;"
        class="row-between"
        @click="checkSelect(3)"
      >
        <p class="popup-title">自定义使用积分数量</p>
        <img
          class="popup-radio"
          v-if="radioSelect != 3"
          src="../assets/icon/integral/radio.png"
        />
        <img
          class="popup-radio"
          v-else
          src="../assets/icon/integral/radio-select.png"
        />
      </div>
      <div
        v-if="radioSelect == 3"
        class="row-start"
        style="margin-top:10px;"
      >
        <p
          style="margin-left: 40px;"
          class="digit-tip"
        >使用</p>
        <div class="col-center">
          <van-field
            class="digit-field"
            v-model="userIntegral"
            type="digit"
          />
          <p class="digit-field-tip">本单可使用{{deductIntegral}}积分</p>
        </div>
        <p class="digit-tip">积分，抵扣<span v-if="userIntegral">¥{{buyUserIntegral}}</span></p>
      </div>
      <button
        :style="radioSelect == 3 ? 'margin-top: 10px;' : 'margin-top: 67px;'"
        @click="confirmUserIntegral()"
        class="integral-confirm"
      >确 定</button>
    </van-popup>
    <!-- 优惠券使用 -->
    <van-popup
      v-model="isShwCoupons"
      @close="closeUserCoupons"
      closeable
      position="bottom"
      style="height: 500px;background: #262626;position: fixed;bottom: 0px !important;left: 0;"
    >
      <p class="coupons-list-title">优惠券</p>
      <div class="popup-line"></div>
      <div class="user-coupons-list">
        <div
          v-for="(item,index) in couponsProductList"
          @click="selectCoupons(item)"
          :key="index"
          class="coupon-item row-center"
          :class="item.couponType == 1 ? 'coupon-item2' : 'coupon-item1'"
        >
          <div class="coupon-price col-center">
            <p class="coupon-price-main">
              <span style="font-size: 18px;font-weight: 400 ;">¥</span>
              {{ item.couponPrice }}
            </p>
            <p class="coupon-price-tip">满{{item.useMinPrice}}元可用</p>
          </div>
          <div class="coupon-item-info col-start">
            <div class="coupon-item-type row-center">{{item.couponType == 1 ? '限品满减券' : '通用满减券'}}</div>
            <p class="coupon-item-title">{{ item.couponTitle.length >= 30 ? item.couponTitle.substring(0,30) : item.couponTitle }}</p>
            <p class="coupon-item-time">{{ item.endTime }}</p>
          </div>
          <div class="coupon-item-icon row-center">
            <img
              v-if="userCouponsSelect[0].id == item.id"
              src="../assets/icon/coupon/select.png"
            />
            <img
              v-if="userCouponsSelect[0].id !== item.id"
              src="../assets/icon/coupon/no-select.png"
            />
          </div>

        </div>
      </div>
      <button
        style="margin-top: 10px;"
        @click="confirmUserCoupons()"
        class="integral-confirm"
      >确 定</button>
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue';
import api from '../api/index-client'
import { getAuthCode } from '../lib/wechat.js'
import { mapGetters } from "vuex";
import Cookies from 'js-cookie'
import config from '../config'
import { RadioGroup,Radio,Popup,Field } from 'vant';

Vue.use(Field);
Vue.use(Radio);
Vue.use(Popup);
Vue.use(RadioGroup);
export default {
  data () {
    return {
      orderKey: '',
      goodInfo: {
        storeInfo: {
          image: ''
        }
      },
      isShwCoupons: false,
      userCouponsSelect: [
        {
          id: null,
          couponPrice: null
        }
      ],
      deductRate: 0.01,
      deductIntegral: 2300,
      radioSelect: 1,
      integralRate: 1,
      isShowIntegral: false,
      totalCount: null,
      totalPrice: null,
      payPrice: null,
      buyIntegral: false,
      allIntegral: null,
      userIntegral: null,
      userDeductIntegral: null,
      couponsProductList: [],
      payType: 'weixin',
      buyType: '3',
      goodDetailId: null,
      isCoupon: 0
    }
  },
  computed: {
    ...mapGetters(["userOpenId"]),
    wxPay () {
      return this.isWechat() || this.buyType == '1' || this.buyType == '3'
    },
    buyUserIntegral () {
      return Math.floor(Math.floor(parseInt(this.userIntegral) / this.integralRate) * this.deductRate * 100) / 100
    },
    buyUserDeductIntegral () {
      return Math.floor(Math.floor(parseInt(this.userDeductIntegral) / this.integralRate) * this.deductRate * 100) / 100
    },
    buyDeductIntegral () {
      return Math.floor(Math.floor(parseInt(this.deductIntegral) / this.integralRate) * this.deductRate * 100) / 100
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.totalCount = JSON.parse(localStorage.getItem('buyDetailInfo')).totalCount
    this.totalPrice = JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice
    this.payPrice = JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice
    this.orderKey = JSON.parse(localStorage.getItem('orderCreate')).orderKey
    localStorage.setItem('payType',this.payType)
    // 商品详情中获取订单信息
    this.allIntegral = JSON.parse(localStorage.getItem('buyDetailInfo')).integral
    this.buyIntegral = localStorage.getItem('isDeduct') == '1' ? true : false
    this.integralRate = localStorage.getItem('integralRate')
    this.deductIntegral = parseInt(this.allIntegral) > (parseInt(localStorage.getItem('deductIntegral')) * this.totalCount) ? (localStorage.getItem('deductIntegral') * this.totalCount) : JSON.parse(localStorage.getItem('buyDetailInfo')).integral
    this.goodInfo = JSON.parse(localStorage.getItem('buyGoodDetail'))
    this.isCoupon = localStorage.getItem('isCoupon')
    this.goodDetailId = localStorage.getItem('goodDetailId')


    this.buyType = localStorage.getItem('buyType')
    switch (this.buyType) {
      case '0':
        this.payType = '0'
        break;
      case '1':
        this.payType = 'weixin'
        localStorage.setItem('orderCreate',JSON.stringify({ orderKey: this.orderKey,payType: this.payType }))
        break;
      case '2':
        this.payType = 'alipay'
        localStorage.setItem('orderCreate',JSON.stringify({ orderKey: this.orderKey,payType: this.payType }))
        break;
      case '3':
        this.payType = 'weixin'
        localStorage.setItem('orderCreate',JSON.stringify({ orderKey: this.orderKey,payType: this.payType }))
        break;
      default:
        this.payType = 'weixin'
        localStorage.setItem('orderCreate',JSON.stringify({ orderKey: this.orderKey,payType: this.payType }))
        break;
    }
    // 商品购买信息从localStorage中获取
    if (window.location.search.split('?code=').length == 2) {
      this.getOpenid(window.location.search.split('?code=')[1].split('&state=')[0])
    } else {
      this.getCouponsProduct()
    }
  },
  methods: {
    // 获取用户该商品可使用优惠券
    getCouponsProduct () {
      api
        .get('coupons/product?productId=' + this.goodDetailId + '&page=0&limit=65535')
        .then(result => {
          if (result.data.success) {
            this.couponsProductList = result.data.data.filter(i => parseFloat(i.useMinPrice) <= JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice)
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
            this.isBuyNow = false
          }
        })
    },
    // 根据code获取openId
    getOpenid (code) {
      api.get('/wechat/getOpenid?code=' + code)
        .then(result => {

          if (result.data.success) {
            if (this.userOpenId != result.data.data.openId) {
              this.setOpenId(result.data.data.openId)
            } else {
              this.pay()
              // this.$store.commit('HIDE_APPLOADING')
            }
            this.$store.commit("SET_USEROPENID",result.data.data.openId)
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 修改用户OpenId
    setOpenId (openId) {
      api.post('user/updateOpenid',{ openId: openId })
        .then(result => {

          if (result.data.success) {
            // this.$store.commit('HIDE_APPLOADING')
            this.pay()
            this.$store.commit("SET_USEROPENID",result.data.data.openId)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // radio选择
    closeUserIntegral () {
      console.log(1111);
      if (this.radioSelect == 3 && !this.userIntegral) {
        this.radioSelect = 1
        this.userIntegral = 0
      }
      if (parseInt(this.userIntegral) > parseInt(this.deductIntegral)) {
        this.radioSelect = 1
      }
      if (parseInt(this.userIntegral) < parseInt(this.integralRate) * 10) {
        this.radioSelect = 1
      }
    },
    // 关闭使用优惠券
    closeUserCoupons () {
      this.isShwCoupons = false
    },
    // 选择优惠券
    confirmUserCoupons () {
      let deductIntegral = parseInt(this.allIntegral) > (parseInt(localStorage.getItem('deductIntegral')) * this.totalCount) ? (localStorage.getItem('deductIntegral') * this.totalCount) : JSON.parse(localStorage.getItem('buyDetailInfo')).integral
      if (this.radioSelect == 1) {

        this.totalPrice = (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100
        this.payPrice = (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100
      } else if ((parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(deductIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0))) >= parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) {

        this.totalPrice = this.radioSelect == 1 ? JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice :
          this.radioSelect == 2 ? (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(this.deductIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100 :
            (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(this.userIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100
        this.payPrice = this.radioSelect == 1 ? JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice :
          this.radioSelect == 2 ? (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(this.deductIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100 :
            (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(this.userIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100
      } else {
        this.userDeductIntegral = 0
        localStorage.setItem('userDeductIntegral',this.userDeductIntegral)
        this.radioSelect = 1
        this.totalPrice = (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100
        this.payPrice = (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100
      }
      localStorage.setItem('userCouponsSelectId',this.userCouponsSelect[0].id)
      this.isShwCoupons = false
    },
    // 确认使用积分
    confirmUserIntegral () {
      if (parseInt(this.userIntegral) > parseInt(this.deductIntegral)) {

        this.$toast({
          message: '不得大于当前可用积分',
          icon: require('../assets/icon/toast-error.png'),
        });
      } else if (parseInt(this.userIntegral) < parseInt(this.integralRate) * 10) {
        this.$toast({
          message: '使用积分不得低于' + parseInt(this.integralRate) * 10,
          icon: require('../assets/icon/toast-error.png'),
        });
      } else {
        this.userDeductIntegral = this.radioSelect == 1 ? 0 : this.radioSelect == 2 ? parseInt(this.deductIntegral) : parseInt(this.userIntegral)
        localStorage.setItem('userDeductIntegral',this.userDeductIntegral)
        this.totalPrice = this.radioSelect == 1 ? JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice :
          this.radioSelect == 2 ? (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(this.deductIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0))) / 100 :
            (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(this.userIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0))) / 100
        this.payPrice = this.radioSelect == 1 ? JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice :
          this.radioSelect == 2 ? (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(this.deductIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0))) / 100 :
            (parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).payPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(this.userIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0))) / 100
        if (this.userCouponsSelect[0].id) {
          this.totalPrice = (parseInt((this.totalPrice * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100
          this.payPrice = (parseInt((this.payPrice * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))) / 100
        }
        this.isShowIntegral = false
      }

    },
    checkSelect (e) {
      this.radioSelect = e
      this.userIntegral = e == 3 ? 1 : this.deductIntegral
    },
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },
    // 支付方式选择
    selectPay (e) {
      this.payType = e == 'zfb' ? 'alipay' : 'weixin'
      localStorage.setItem('orderCreate',JSON.stringify({ orderKey: this.orderKey,payType: this.payType }))
      document.getElementById('zfbSelect').style.display = e == 'zfb' ? "block" : "none";
      document.getElementById('zfbCancer').style.display = e == 'zfb' ? "none" : "block";
      document.getElementById('wxSelect').style.display = e == 'zfb' ? "none" : "block";
      document.getElementById('wxCancer').style.display = e == 'zfb' ? "block" : "none";
    },
    // 使用积分
    integralUser () {
      let deductIntegral = parseInt(this.allIntegral) > (parseInt(localStorage.getItem('deductIntegral')) * this.totalCount) ? (localStorage.getItem('deductIntegral') * this.totalCount) : JSON.parse(localStorage.getItem('buyDetailInfo')).integral
      if (this.userCouponsSelect[0].id && ((parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt(((Math.floor(parseInt(deductIntegral) / this.integralRate) * this.deductRate) * 100).toFixed(0))) < parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0)))) {
        // console.log(parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0)));
        this.deductIntegral = parseInt((JSON.parse(localStorage.getItem('buyDetailInfo')).totalPrice * 100).toFixed(0)) - parseInt((this.userCouponsSelect[0].couponPrice * 100).toFixed(0))
      } else {
        this.deductIntegral = parseInt(this.allIntegral) > (parseInt(localStorage.getItem('deductIntegral')) * this.totalCount) ? (localStorage.getItem('deductIntegral') * this.totalCount) : JSON.parse(localStorage.getItem('buyDetailInfo')).integral
      }
      this.isShowIntegral = true
    },
    // 使用优惠券
    couponsUser () {

      this.isShwCoupons = true
    },

    getAppId () {
      if (this.buyType == '0') {
        // 未开发购买渠道
      } else if (this.isWechat() && (this.buyType == '1' || this.buyType == '3')) {
        window.location.href = getAuthCode(window.location.hash)
      } else if (this.isWechat() && this.buyType == '2') {
        // 微信内使用支付宝
      } else {
        this.pay()
      }
    },
    selectCoupons (e) {
      console.log(this.userCouponsSelect[0]);
      console.log(e);
      if (this.userCouponsSelect && (this.userCouponsSelect[0].id == e.id)) {
        this.userCouponsSelect = [
          {
            id: 0,
            couponPrice: null
          }
        ]
        this.$forceUpdate()
      } else {
        this.userCouponsSelect = []
        this.userCouponsSelect.push(e)
        this.$forceUpdate()
      }

    },
    // 支付处理逻辑
    pay () {
      let payFrom
      payFrom = this.payType == 'alipay' ? 'alih5' : this.isWechat() ? 'wechat' : 'weixinh5'
      let userDeductIntegral = localStorage.getItem('userDeductIntegral') ? localStorage.getItem('userDeductIntegral') : 0,
        userCouponsSelectId = localStorage.getItem('userCouponsSelectId') ? localStorage.getItem('userCouponsSelectId') : 0

      api
        .post('order/create/' + this.orderKey,{ couponId: userCouponsSelectId,payType: this.payType,from: payFrom,isPay: 1,useIntegral: userDeductIntegral },{},{ 'X-Requested-With': 'XMLHttpRequest','Access-Control-Allow-Origin': '*','Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization': "Bearer " + Cookies.get('Login') })
        .then(result => {
          if (result.data.success) {
            localStorage.removeItem('userDeductIntegral')
            localStorage.removeItem('userCouponsSelectId')
            if (Cookies.get('Device') == 'pico') {
              this.$router.replace('/share?unique=' + this.orderKey + 'type=buy')
            } else {
              if (this.payType == 'alipay') {
                localStorage.setItem('buyDetailReset',1)
                const divForm = document.getElementsByTagName('divform')
                if (divForm.length) {
                  document.body.removeChild(divForm[0])
                }
                const div = document.createElement('divform');
                div.innerHTML = result.data.data.result.jsConfig.mweb_url; // data就是接口返回的form 表单字符串
                document.body.appendChild(div);
                // document.forms[0].setAttribute('target','_blank') // 新开窗口跳转
                document.forms[0].submit();
              } else {
                if (this.isWechat()) {
                  window.location.href = config.locationHref + 'paySuccess?unique=' + JSON.parse(localStorage.getItem('orderCreate')).orderKey + '&type=buy'
                  function onBridgeReady () {
                    WeixinJSBridge.invoke(
                      'getBrandWCPayRequest',{
                      "appId": result.data.data.result.jsConfig.appId,     //公众号ID，由商户传入     
                      "timeStamp": result.data.data.result.jsConfig.timestamp,         //时间戳，自1970年以来的秒数     
                      "nonceStr": result.data.data.result.jsConfig.nonceStr, //随机串     
                      "package": result.data.data.result.jsConfig.package,
                      "signType": result.data.data.result.jsConfig.signType,         //微信签名方式：     
                      "paySign": result.data.data.result.jsConfig.paySign //微信签名 
                    },
                      function (res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                          // 使用以上方式判断前端返回,微信团队郑重提示：
                          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                          // window.location.href = config.locationHref + 'paySuccess?unique=' + JSON.parse(localStorage.getItem('orderCreate')).orderKey + '&type=buy'
                        }
                      });
                  }
                  if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                      document.addEventListener('WeixinJSBridgeReady',onBridgeReady,false);
                    } else if (document.attachEvent) {
                      document.attachEvent('WeixinJSBridgeReady',onBridgeReady);
                      document.attachEvent('onWeixinJSBridgeReady',onBridgeReady);
                    }
                  } else {
                    onBridgeReady();
                  }
                } else {
                  setTimeout(() => {
                    window.location.href = result.data.data.result.jsConfig.mweb_url
                  },300);
                  window.location.href = config.locationHref + 'paySuccess?unique=' + this.orderKey + '&type=buy'
                }
              }
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    }

  }
}
</script>
<style lang="less" scoped>
.buy-detail-container {
  margin-top: 3.875rem;
  margin-bottom: 3.875rem;
  height: calc(100vh - 8rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .good-detail-name {
    font-size: 22px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #ffffff;
    line-height: 31px;
    margin-bottom: 0.5rem;
  }

  .good-detail-prise {
    font-size: 14px;
    font-family: SFUIDisplay-Bold, SFUIDisplay;
    font-weight: bold;
    color: #ffffff;
    line-height: 16px;
  }
  .good-detail-bottom-prise {
    font-size: 22px;
    font-family: SFUIDisplay-Bold, SFUIDisplay;
    font-weight: bold;
    color: #ffffff;
    line-height: 16px;
    margin-left: 20px;
  }

  .order-detail-top {
    background: #1c1c1c;
    padding: 33px 5% 24px 5%;
  }

  .order-detail-amount-container {
    width: 90%;
    margin-left: 5%;
    height: 52px;
    background: #262626;
    border-radius: 4px;
  }

  .order-detail-amount {
    width: 100%;
    height: 52px;
  }

  .order-good-amount {
    font-size: 16px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #e6e6e6;
    line-height: 19px;
  }

  .order-good-amount-title,
  .order-good-prise-title {
    font-size: 14px;
    font-weight: 500;
    color: #e6e6e6;
    line-height: 20px;
  }

  .order-good-amount-title {
    margin-left: 1.1875rem;
  }

  .order-good-amount {
    margin-right: 1.625rem;
  }

  .line {
    width: 90%;
    height: 1px;
    background: #323232;
  }

  .order-detail-prise {
    margin-top: 0.75rem;
    width: 90%;
    margin-left: 5%;
    background: #262626;
    border-radius: 4px;
  }

  .order-good-prise-title {
    margin-left: 1.1875rem;
  }

  .all-order-good-prise,
  .order-good-prise {
    font-size: 17px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #e6e6e6;
    line-height: 19px;
    margin-right: 1.5625rem;
  }

  .order-detail-prise-buy {
    margin-top: 12px;
    width: 90%;
    margin-left: 5%;
    height: 120px;
    background: #262626;
    border-radius: 4px;
  }

  .order-detail-zfb,
  .order-detail-wx {
    width: 100%;
  }

  .buy-zfb-select,
  .buy-wx-cancer {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1.5625rem;
    display: none;
  }

  .buy-zfb-cancer,
  .buy-wx-select {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1.5625rem;
    display: block;
  }

  .zfb-icon {
    width: 1.625rem;
    height: auto;
    margin: 0 2rem 0 1.1875rem;
  }
  .wx-icon {
    width: 2rem;
    height: auto;
    margin: 0 1.75rem 0 1.1875rem;
  }

  .order-detail-zfb {
    margin-top: 1rem;
  }

  .order-detail-wx {
    margin-bottom: 1rem;
  }

  .zfb-tip,
  .wx-tip {
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #e4e4e4;
    line-height: 19px;
  }

  .order-pay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #080808;
  }

  .pay-now {
    width: 200px;
    height: 2.5rem;
    margin-right: 10px;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .integral-confirm {
    width: 80vw;
    margin-left: 10vw;
    height: 2.5rem;
    margin-right: 10px;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .no-access-pay {
    background-image: url('../assets/icon/open-soon.png') !important;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: cover !important;
    background-color: transparent;
  }
  .good-prise-next {
    height: 10px;
    width: auto;
    margin: 0 0 0px 5px;
  }
  .integral-rule-title {
    text-align: left;
    margin-left: 30px;
    color: #ffffff;
    font-weight: 800;
    margin-top: 20px;
  }
  .popup-line {
    width: 94vw;
    margin-left: 3vw;
    height: 1px;
    background: #3e3d3d;
    margin-top: 10px;
  }
  .popup-title {
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    margin-left: 30px;
  }
  .digit-tip {
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
  }
  .digit-field-tip {
    font-size: 10px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #929292;
  }
  .popup-radio {
    width: 15px;
    height: auto;
    margin-right: 30px;
  }
  .digit-field {
    width: 120px;
    margin: 0 10px;
    padding: 0 16px;
    height: 30px;
    background: #2d2d2d;
  }
  /deep/.van-field__control {
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    margin-top: 4px;
  }
  .digit-field::after {
    display: none;
  }
}
.coupons-list-title {
  text-align: center;
  color: #ffffff;
  font-weight: 800;
  margin-top: 20px;
}
.user-coupons-list {
  width: 100vw;
  height: 370px;
  overflow-y: auto;
}
.coupon-item1 {
  background-image: url('../assets/icon/coupon/buy-coupons.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: contain;
}
.coupon-item1 {
  background-image: url('../assets/icon/coupon/buy-coupons-select1.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: contain;
}
.coupon-item2 {
  background-image: url('../assets/icon/coupon/buy-coupons-select.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: contain;
}
.coupon-item {
  width: 92vw;
  height: 21vw;
  margin: 15px 4vw 0 4vw;
  position: relative;
  .coupon-item-tip {
    width: 86vw;
    min-height: 15vw;
    height: auto;
    margin: 15px 4vw 0 4vw;
    padding: 3vw;
    background: #ffffff;
    border-radius: 10px;
    color: #000000;
    font-size: 13px;
    font-weight: 400;
  }
  .coupon-item-icon {
    width: 8.9%;
    height: 21vw;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .coupon-item-info {
    width: 68%;
    height: 21vw;
    .coupon-item-type {
      position: absolute;
      top: 2vw;
      left: 24.5vw;
      font-size: 12px;
      color: #ffffff;
      width: 19vw;
      height: 5vw;
    }
    .coupon-item-title {
      position: absolute;
      top: 2vw;
      left: 24.5vw;
      width: 66%;
      height: 12vw;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      text-indent: 20vw;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .coupon-item-time {
      color: #696969;
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      bottom: 2vw;
      left: 24.5vw;
    }
  }
  .coupon-price {
    width: 23.1%;
    height: 21vw;
    .coupon-price-main {
      font-size: 24px;
      color: #ffffff;
      font-weight: bold;
    }
    .coupon-price-tip {
      font-size: 12px;
      color: #ffffff;
      font-weight: 300;
    }
  }
}
</style>
